.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex;
  flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
}

#tags, .tag {
  display: flex;
}

#tags {
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0;
}

.tag {
  width: auto;
  height: 32px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 10px;
  margin: 0 8px 8px 0;
  background: #6acadd 0 0 no-repeat padding-box;
}

.tag .tag-title {
  margin-top: 3px;
}

.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.tags-input input {
  flex: 1 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0;
  background: none;
}

.tags-input input:focus-visible {
  outline: none;
}

/* .main_container {
  width: 25%;
} */

.rhap_progress-section {
  flex: 0 auto !important;
}

.rhap_controls-section {
  display: flex;
  flex: none !important;
  justify-content: space-between;
  align-items: center;
}

.rhap_header,
.rhap_time,
.rhap_main-controls,
.rhap_container svg {
  color: #fff !important;
}

img.show-img-holder {
  /* width: auto; */
  /* height: 350px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.rhap_header, .rhap_time, .rhap_main-controls, .rhap_container svg {
  color: #f00 !important;
}

.rhap_container {
  box-shadow: none !important;
}

.rhap_container:hover {
  background: #efefef  !important;
  cursor: pointer;
}

.rhap_footer {
  text-align: center;
}

.rhap_footer {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

.overview-des {
  text-align: left;
  font-size: 15px;
}

.od {
  font-size: 15px;
  text-align-last: left;
}
